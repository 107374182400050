@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&family=Roboto:wght@300;400;500;600;700;800;900&display=swap');


:root {
  --rgb-color-1: 46 81 147;
  --bs-primary-rgb: 46, 81, 147;
  --rgb-color-2: 255 110 0;
  --bs-secondary-rgb: 255, 110, 0;
  --color-0: #273049;
  --color-1: #2e5193;
  --color-2: #ff6e00;
  --color-3: #FF8E37;
  --color-4: #e2e8f3;
  --font-color: #252525;
  --font-fam: "Roboto";
  --extra-font-fam: "PT Sans";
  --transition: .25s ease-in;
  --h-top: 80px;
  --h-nav: 50px;
  --f-height: 240px;
  --white: #fff;
  --extralight-gray: #f3f3f3;
  --light-gray: #E8E8E8;
  --gray: #C4C4C4;
  --dark-gray: #8D8D8D;
  --black: #252525;

  @media (max-width: 991px) {
    --h-top: 65px;
    --h-nav: 40px;
    --f-height: 65px;
  }

  @media (max-width: 575px) {
    --h-top: 55px;
    --f-height: 48px;
  }
}

$main-font: "Roboto";
$title-font: "Roboto";
$extra-font: "PT Sans";

$color-0: #273049;
$color-1: #2e5193;
$color-2: #ff6e00;
$color-3: #FF8E37;
$color-4: #e2e8f3;
$color-5: #F3F6FC;

$red: #ee1000 !important;
$green: #08af40;

$white: #ffffff;
$extralight-gray: #f3f3f3;
$light-gray: #E8E8E8;
$gray: #C4C4C4;
$dark-gray: #8D8D8D;
$darker: #595959;
$black: #252525;
$total-black: #000;
$transition: 300ms ease-in-out;
$radius: 3px;
$radius-xl: 20px;

/* mixins */
@mixin flex($dir: row, $x: center, $y: center, $wrap: nowrap) {
  display: flex;
  flex-direction: $dir;
  justify-content: $x;
  align-items: $y;
  flex-wrap: $wrap;
}

@mixin no-appearance {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  outline: none;
}

@mixin safariFix {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  isolation: isolate;
  transform: translateZ(0);
}

@mixin absBG($z: 1, $top: 0%, $h: 100%) {
  position: absolute;
  top: $top;
  left: 0px;
  width: 100%;
  height: $h;
  z-index: $z;
}

@mixin clamp($count, $line, $strict: false) {
  display: -webkit-box;
  -webkit-line-clamp: $count;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: $line;

  @if $strict {
    height: $line * $count;
  }

  @else {
    max-height: $line * $count;
  }
}

@mixin list {
  list-style: none;
  padding-left: 0px;
}

@mixin shadow {
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8),
    0px 4px 15px rgba(100, 100, 100, 0.08);
  backdrop-filter: blur(4px);
}

@mixin boxShadow($size: 10px) {
  box-shadow: 0px 0px $size rgba(0, 0, 0, 0.25);
}

@mixin lightShadow {
  filter: drop-shadow(0px 4px 15px rgba(100, 100, 100, 0.08));
}

@mixin shadowMobile {
  box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5),
    1px 1px 10px rgba(100, 100, 100, 0.15);
  backdrop-filter: blur(2.5px);
}

@mixin blob {
  box-shadow: inset 0px 1px 3px rgba(245, 201, 249, 0.47);
  filter: drop-shadow(0px 1px 3px rgba(70, 67, 118, 0.2));
}

@mixin btnStyle {
  box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.3),
    0px 3px 10px rgba(156, 156, 156, 0.2);
}

@mixin blurShadow {
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8),
    0px 5px 25px rgba(95, 95, 95, 0.08);
  backdrop-filter: blur(5px);
}

@mixin placeholder($color, $fw: 400) {

  &:-moz-placeholder,
  &::-moz-placeholder,
  &::-webkit-input-placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder {
    color: $color;
    font-weight: $fw;
  }

  &::placeholder {
    color: $color;
    font-weight: $fw;
  }
}

@mixin rounedImg($w) {
  width: $w;
  height: $w;
  border-radius: 50%;
  object-fit: cover;
}

@mixin roundedImg($w, $rad: 50%, $fit: cover) {
  width: $w;
  height: $w;
  border-radius: $rad;
  object-fit: $fit;
}

@mixin textEllipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}


/* Typography */
a,
a:hover,
a:active {
  color: inherit;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
span,
a,
button,
form,
fieldset,
legend,
select,
input,
textarea,
button,
div,
blockquote,
cite,
figure,
figcaption,
table,
ul,
ol,
dl,
li,
address {
  line-height: 135%;
  margin-bottom: 0px;
}

h1,
.h1 {
  font-family: var(--extra-font-fam);
  font-size: 2em;
  font-weight: 400;
  color: var(--font-color);
  text-align: left;
  margin-bottom: 1em;

  @media (max-width: 575px) {
    font-size: 1.5em;
  }

  &.large {
    font-size: 5em;

    @media (max-width: 1199px) {
      font-size: 4.5em;
    }

    @media (max-width: 991px) {
      font-size: 4em;
    }

    @media (max-width: 767px) {
      font-size: 3.5em;
    }

    @media (max-width: 575px) {
      font-size: 2.5em;
    }
  }
}

h2,
.h2 {
  font-family: var(--roboto);
  text-align: center;
  font-size: 2em;
  font-weight: 500;
  margin-bottom: 1em;
  text-transform: uppercase;

  @media (max-width: 991px) {
    font-size: 1.75em;
  }

  @media (max-width: 575px) {
    font-size: 1.5em;
    margin-bottom: 0.7em;
  }

  &::before,
  &::after {
    content: "________";
    color: var(--color-2);
    margin: 0 .5em;

    @media (max-width: 991px) {
      content: unset;
    }
  }
}

h3 {
  font-size: 1.25em;
  font-weight: 700;
  text-align: left;
  margin-bottom: 0.2em;
}

h4 {
  font-size: 1.125em;
  font-weight: 500;
  text-align: left;
  margin-bottom: 1em;
}

h5 {
  font-size: 1em;
  font-weight: 400;
  text-align: left;
  margin-bottom: 0px;
}

h6 {
  font-size: 1em;
  font-weight: 700;
  text-align: left;
  margin-bottom: 0px;
}

.fs {
  &-08 {
    font-size: 0.8em;
  }

  &-09 {
    font-size: 0.9em;
  }

  &-10 {
    font-size: 1em;
  }

  &-11 {
    font-size: 1.1em;
  }

  &-12 {
    font-size: 1.2em;
  }

  &-13 {
    font-size: 1.3em;
  }

  &-14 {
    font-size: 1.4em;
  }

  &-15 {
    font-size: 1.5em;
  }

  &-16 {
    font-size: 1.6em;
  }

  &-17 {
    font-size: 1.7em;
  }

  &-18 {
    font-size: 1.8em;
  }

  &-19 {
    font-size: 1.9em;
  }

  &-20 {
    font-size: 2em;
  }

  &-25 {
    font-size: 2.5em;
  }

  &-30 {
    font-size: 3em;
  }

  &-35 {
    font-size: 3.5em;
  }

  &-40 {
    font-size: 4em;
  }

  &-50 {
    font-size: 5em;
  }
}

.fw {
  &-3 {
    font-weight: 300;
  }

  &-4 {
    font-weight: 400;
  }

  &-5 {
    font-weight: 500;
  }

  &-6 {
    font-weight: 600;
  }

  &-7 {
    font-weight: 700;
  }

  &-8 {
    font-weight: 800;
  }

  &-9 {
    font-weight: 900;
  }
}

/* font colors */
.color-0 {
  color: $color-0;
}

.color-1 {
  color: $color-1;
}

.color-2 {
  color: $color-2;
}

.white {
  color: $white;
}

.light-gray {
  color: $light-gray;
}

.gray {
  color: $gray;
}

.dark-gray {
  color: $dark-gray;
}

.darker {
  color: $darker;
}

.black {
  color: var(--black);
}

.red,
.text-danger {
  color: $red;
}

.title-font {
  font-family: $title-font;
}

.extra-font {
  font-family: $extra-font;
}

.link {
  color: $color-1;
  transition: color $transition;

  &:hover,
  &:active {
    color: $color-2;
  }
}


/* Form elements */
input,
.input,
textarea {
  background: #fff;
  color: $black;
  border: 1px solid $gray;
  border-radius: 3px;
  padding: 0.55em 1.25em;
  width: 100%;
  outline: none;
  display: block;
  transition: $transition;
  @include placeholder($gray, 400);

  &:focus {
    border: 1px solid $color-1;
    box-shadow: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    -webkit-text-fill-color: var(--font-color) !important;
  }
}

input {
  &[type="checkbox"] {
    @include no-appearance();
    width: 1em;
    height: 1em;
    border: 1px solid $gray;
    border-radius: $radius;
    box-shadow: none;
    padding: 0px;
    margin: 0px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 70%;
      height: 40%;
      border-color: transparent;
      border-width: 0px 0px 2px 2px;
      border-style: solid;
      transform: translate(-50%, -80%) rotate(-45deg);
      transition: border-color $transition;
    }

    &:checked {
      &::before {
        border-color: $color-2;
      }
    }
  }

  &[type="radio"] {
    @include no-appearance;
    cursor: pointer;
    width: 1.25em;
    height: 1.25em;
    border: 2px solid var(--dark-gray);
    background-color: transparent;
    border-radius: 50%;
    box-shadow: none;
    padding: 0px;
    margin: 0px;
    transition: $transition;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: -20%;
      left: -20%;
      width: 70%;
      height: 70%;
      border-radius: 100%;
      transform: translate(50%, 50%);
      transition: $transition;
    }

    &:checked {
      border: 2px solid var(--color-1) !important;

      &::after {
        background-color: var(--color-1) !important;
      }
    }
  }

  &.switch {
    @include no-appearance;
    width: 40px;
    height: 20px;
    border: none;
    border-radius: 10px;
    background-color: var(--light-gray);

    &::before {
      content: "";
      position: absolute;
      top: 2px;
      left: 3px;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background-color: var(--white);
      transition: $transition;
      opacity: 1;
      border: none;
      transform: unset;
    }

    &:checked {
      background-color: var(--green);

      &::before {
        left: calc(100% - 19px);
      }
    }
  }

  &.code {
    font-family: "Bebas";
    font-size: 2.3em;
    padding: 0px 10px;
    text-align: center;
    line-height: 120%;
  }
}

label {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    flex: 1;
  }
}

.input-rating {
  input {
    display: none;
  }

  .stars {
    display: flex;
    flex-direction: row-reverse;
    width: fit-content;

    button {
      display: flex;
      color: var(--blue);
      font-size: 2.15em;

      &:not(:last-child) {
        margin-left: 0.2em;
      }

      svg {
        path {
          stroke: var(--blue);
          fill: var(--white);
          transition: $transition;
        }
      }

      &:hover,
      &.active,
      &.active~button,
      &:hover,
      &:hover~button {
        svg {
          path {
            fill: var(--blue);
          }
        }
      }
    }
  }
}

.password {
  position: relative;

  input {
    font-size: inherit;
    font-weight: inherit;
    position: relative;
    z-index: 1;
  }

  button {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 5;
    color: var(--gray);
    height: 100%;
    width: 2em;
    font-size: 1.6em;
    @include flex();

    svg {
      path {
        stroke-width: 1.25px;
      }
    }
  }
}

select {
  @include no-appearance();
  cursor: pointer;
  min-width: 250px;
  border-radius: $radius;
  border: 1px solid $gray;
  padding: 0.6em 1.25em;
  background: url(imgs/icons/chevron-down.svg), $white;
  color: inherit;
  background-repeat: no-repeat;
  background-position: calc(100% - 1em) 50%;
  background-size: 0.67em;
  transition: $transition;

  @media (max-width: 767px) {
    min-width: 200px;
  }
}

fieldset.labeled-input {
  border: 1px solid $gray;
  border-radius: $radius;
  position: relative;

  legend {
    float: unset;
    width: fit-content;
    margin-left: 10px;
    color: $dark-gray;
    font-size: .8em;
    padding: 0px 5px;
    margin-bottom: -0.3em;
    z-index: 10;
    position: relative;

    &:empty {
      display: none;
    }
  }

  input,
  select,
  textarea {
    border: none;
  }

  .error-text {
    position: absolute;
    top: 100%;
    left: 0%;
    color: $red;
    font-size: 0.8em;
  }
}

.files {
  &-label {
    position: relative;
    @include flex();
    transition: $transition;

    &:hover {
      cursor: pointer;
    }

    input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      width: 1px;
      height: 1px;
      top: 0px;
      left: 0px;
      padding: 0px;
    }

    .icon {
      @include flex();
      width: 2.5em;
      height: 2.5em;
      border-radius: 50%;
      border: 1px solid rgba($color: var(--pale-blue), $alpha: 0.35);

      svg {
        color: var(--blue);
        font-size: 1.5em;
        stroke-width: 1.25px;
      }
    }

    .ind {
      position: absolute;
      bottom: -0.25em;
      right: 0px;
      color: var(--blue);
      font-weight: 600;
      line-height: 1em;
      font-size: 0.9em;

      &:empty {
        display: none;
      }
    }
  }

  &-list {
    @include list();
    @include flex(row, flex-start, stretch, nowrap);
    flex-wrap: wrap !important;

    li {
      img {
        @include roundedImg(100px, $radius, cover);
      }
    }
  }
}

.hv-100 {
  min-height: calc(100vh - var(--h-top) - var(--f-height) - 4em);
}

button {
  background: none;
  border: none;
  padding: unset;
  display: block;
  font-family: inherit;
  outline: none;
  color: inherit;

  &[disabled]:not(.btn) {
    opacity: 0.5 !important;
  }
}

.btn {
  @include flex(row, center, center, nowrap);
  padding: 0.6em 2em;
  text-align: center;
  width: fit-content;
  border: none;
  border-radius: $radius;
  font-size: .9em;

  &-none {
    padding: 0px;
  }

  &-1,
  &-primary {
    @extend .btn;
    color: #fff;
    background-color: var(--color-1);
    transition: background-color 300ms ease-in;

    &:hover,
    &:active {
      color: #fff;
      background-color: var(--color-0);
    }
  }

  &-add-offer {
    @extend .btn-1;
    font-size: .7em;
  }

  &-premium {
    @extend .btn;
    padding-top: 2em;
    padding-bottom: 2em;
    border: 1px solid $color-1;
    color: #fff;
    background-color: #22A2FF;
    transition: background-color 300ms ease-in;

    &:hover,
    &:active,
    &.active {
      color: #fff;
      background-color: var(--color-1);
    }
  }

  &-2,
  &-secondary {
    @extend .btn;
    color: #fff;
    background-color: var(--color-2);
    border: none;
    transition: background-color 300ms ease-in;

    &:hover,
    &:active {
      color: #fff;
      background-color: var(--color-3);
    }
  }

  &-info {
    @extend .btn;
    border-radius: $radius-xl;
    border: 3px solid $color-3;
    background-color: $color-3;
    color: $white;
    font-weight: 700;
    padding: 0.67em 2em;
  }

  &-light {
    @extend .btn;
    border-radius: $radius-xl;
    border: 3px solid $color-3;
    background-color: $white;
    color: $color-3;
    font-weight: 900;
    padding: 0.67em 2em;
  }

  &-gray {
    @extend .btn;
    background-color: $color-4;
    color: $dark-gray;
    font-weight: 400;
    transition: background-color 300ms ease-in;


    &:hover,
    &:active {
      color: #fff;
      background-color: var(--color-1);
    }
  }

  &-gray-1 {
    @extend .btn;
    background-color: $color-4;
    color: $dark-gray;
    font-weight: 400;
    transition: background-color 300ms ease-in;

    font-size: .7em;


    &:hover,
    &:active {
      color: #fff;
      background-color: var(--color-1);
    }
  }

  &-fav {
    @include flex(row, center, center, nowrap);
  }

  &[disabled] {
    background-color: $extralight-gray !important;
    color: $dark-gray;
  }
}

.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.input-file-simple {
  position: relative;
  color: var(--gray);
  transition: $transition;

  &:hover {
    cursor: pointer;
    color: var(--blue);
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 1px;
    height: 1px;
    top: 0px;
    left: 0px;
    padding: 0px;
  }

  svg {
    color: currentColor;
    font-size: 1.25em;
  }

  .ind {
    position: absolute;
    bottom: -0.75em;
    right: -0.75em;
    color: var(--white);
    line-height: 1em;
    font-size: 0.75em;
    background: var(--blue);
    width: 1.3em;
    height: 1.3em;
    border-radius: 50%;
    @include flex();

    &:empty {
      display: none;
    }
  }
}

.star-rating {
  @include flex(row, flex-start, center, nowrap);
  color: var(--blue);
  font-size: 1.34em;

  svg {
    &:not(:first-child) {
      margin-left: 3px;
    }

    &.filled {
      path {
        fill: var(--blue);
      }
    }
  }
}

@media (max-width: 575px) {
  .w-xs-100 {
    width: 100%;
  }
}

.flex-1 {
  flex: 1;
}

.box {
  border: 1px solid $light-gray;
  border-radius: $radius;
}

.mb-6 {
  margin-bottom: 6em;

  @media (max-width: 991px) {
    margin-bottom: 5em;
  }

  @media (max-width: 767px) {
    margin-bottom: 4em;
  }

  @media (max-width: 575px) {
    margin-bottom: 3em;
  }
}

.w-200 {
  width: 200px;
}

/* Breadcrumbs */
.breadcrumbs {
  margin-bottom: 2em;
  color: var(--light-gray);

  @media (max-width: 1199px) {
    margin-bottom: 1.5em;
  }

  ul {
    @include list();
    @include flex(row, flex-start, center, wrap);

    li {
      a {
        font-size: 1.2em;
        font-weight: 500;

        @media (max-width: 991px) {
          font-size: 1.1em;
        }

        @media (max-width: 767px) {
          font-size: 1em;
        }
      }

      &:not(:first-child) {
        margin-left: 1em;
        padding-left: 1.4em;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: 0px;
          display: block;
          height: 100%;
          width: 8px;
          background: url(imgs/arrow-mini.svg) no-repeat center;
          background-size: contain;

          @media (max-width: 991px) {
            width: 7px;
          }

          @media (max-width: 767px) {
            width: 6px;
          }
        }
      }
    }
  }
}

/* Pagination */
nav.pagination {
  ul {
    @include flex(row, flex-start, center, nowrap);

    @media (max-width: 575px) {
      justify-content: center;
    }



  }
}

/* Accordion */
.accordion {
  --bs-accordion-color: #1b1b1c;
  --bs-accordion-btn-color: #4a83dc;
  --bs-accordion-active-color: #4a83dc;
  --bs-accordion-btn-bg: transparent;
  --bs-accordion-active-bg: transparent;
  --bs-accordion-btn-padding-y: 0.5em;
  --bs-accordion-btn-padding-x: 0px;
  --bs-accordion-body-padding-x: 0px;
  --bs-accordion-border-color: rgba(157, 196, 239, 0.3);
  --bs-accordion-btn-icon-width: 2em;
  --bs-accordion-btn-icon: url(imgs/chevron-down-blue.svg);
  --bs-accordion-btn-active-icon: url(imgs/chevron-down-blue.svg);

  &-button {
    box-shadow: unset;
    font-weight: 600;
  }
}

/* Dropdown */
.dropdown {
  &-toggle {
    @include flex();

    &::after {
      display: none;
    }
  }

  &-menu {
    --bs-dropdown-spacer: 0.125rem;
    --bs-dropdown-font-size: 1em;
    --bs-dropdown-color: #252525;
    --bs-dropdown-bg: #fff;
    --bs-dropdown-border-color: #C4C4C4;
    --bs-dropdown-border-radius: 5px;
    --bs-dropdown-border-width: 0px;
    --bs-dropdown-inner-border-radius: 4px;
    --bs-dropdown-link-color: #252525;
    --bs-dropdown-link-hover-color: #ff6e00;
    --bs-dropdown-link-hover-bg: #f3f3f3;
    --bs-dropdown-link-active-color: #2e5193;
    --bs-dropdown-link-active-bg: #F3F6FC;
    --bs-dropdown-item-padding-x: 1em;
    --bs-dropdown-item-padding-y: 0.5em;
    --bs-dropdown-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    box-shadow: var(--bs-dropdown-box-shadow);
    padding: 0px;
    overflow: hidden;
    @include safariFix();
  }

  &-item {
    display: block;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    text-align: left;
    width: 100%;
    justify-content: flex-start !important;
    transition: $transition;

    &:hover,
    &:focus {
      color: var(--bs-dropdown-link-hover-color) !important;
      background-color: var(--bs-dropdown-link-hover-bg);
    }

    &:active {
      color: var(--bs-dropdown-link-active-color) !important;
      background-color: var(--bs-dropdown-link-active-bg);
    }
  }
}

.custom-select {
  border-radius: $radius;
  border: 1px solid $gray;
  background-color: white;

  .dropdown-menu {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
  }

  &-toggle {
    @include flex(row, space-between, center, nowrap);
    padding: 0.55em 1.25em;

    &-chevron {
      color: $color-1;
      font-size: 1.3em;
    }
  }
}

/* Badge */
.badge {
  --bs-badge-padding-x: 0em;
  --bs-badge-padding-y: 0em;
  --bs-badge-font-size: 0.75rem;
  --bs-badge-font-weight: 400;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 50%;
  width: 1rem;
  height: 1rem;
  @include flex();
  position: absolute;
  top: -10%;
  right: -10%;
}

/* Carousel */
.carousel {
  .carousel-indicators {
    bottom: 1em;
    margin-bottom: 0px;
    @include flex();

    button {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin: 0px 7px;
      background-color: $gray;
      opacity: 1;
      border: none;

      &.active {
        background-color: $white;
      }
    }
  }
}

/* Pagination */
.pagination {
  @include flex(row, flex-start, stretch, nowrap);


  li {
    font-size: 15px;
    margin-left: 1em;
    // &:not(:first-child) {
    //   margin-left: 1em;
    // }

    &:first-child {
      margin-left: 0; // Обнуление отступа для первой кнопки
    }

    &:first-of-type {
      margin-left: 0;
    }

    a,
    button {
      border: 1px solid $dark-gray;
      font-weight: 500;
      width: 2em;
      height: 2em;
      border-radius: 1px;
      @include flex();
      color: $dark-gray;
      transition: $transition;
      transition-property: color, border-color;


      &:hover,
      &:active,
      &.button-active,
      &.active {
        color: $color-2;
        border: 1px solid $color-2;
      }
    }

    .button-inactive {
      border: 0px solid transparent;
      font-weight: 0;
      width: 0em;
      height: 0em;
      border-radius: 0px;
      @include flex();
      color: transparent;
      transition: $transition;
      transition-property: color, border-color;

      &:hover,
      &:active,
      &.active {
        color: transparent;
        border: 0px solid transparent;
      }
    }
  }
}

/* Swiper */
.swiper {

  &-prev,
  &-next {
    position: absolute;
    top: 50%;
    z-index: 100;
    @include flex();
    color: $black;
    transform: translateY(-50%);
    transition: color $transition;

    &:hover,
    &:focus {
      color: $color-2;
    }

    svg {
      font-size: 3em;
    }
  }

  &-prev {
    left: -3em;
  }

  &-next {
    right: -3em;
  }
}


/*** BODY ***/
body {
  margin: 0;
  font-family: $main-font, "Arial", "Tahoma", "Verdana";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: 400;
  background-color: var(--body-bg);
  color: var(--font-color);
  overflow-x: hidden;

  @media (max-width: 1199px) {
    font-size: 15px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }
}

/*** HEADER ***/
header {
  background-color: var(--color-1);
  color: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);
  position: sticky;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1042;
  height: calc(var(--h-top) + var(--h-nav));

  // @media (max-width:991px) {
  //   height: var(--h-top);
  // }

  .top {
    @include flex(row, space-between, center, nowrap);
    height: var(--h-top);

    .logo {
      width: 250px;

      @media (max-width: 991px) {
        width: 200px;
      }

      @media (max-width: 575px) {
        width: 150px;
      }
    }

    form {
      flex: 1;
      @include flex(row, flex-start, stretch, nowrap);
      justify-content: center;

      @media (max-width:991px) {
        display: none;
      }

      input {
        border-right-width: 0px;
        border-radius: 3px 0px 0px 3px;
        width: 400px;
      }

      button {
        background-color: var(--color-4);
        border: 1px solid rgba(var(--rgb-color-1) / 20%);
        border-radius: 0px 3px 3px 0px;
        color: var(--color-1);
        padding: 0em .75em;

        svg {
          font-size: 1.5em;
        }
      }
    }

    .img {
      position: relative;
      margin-left: 1em;

      img {
        @include rounedImg(2.5em);
        position: relative;
      }
    }
  }

  nav {
    background-color: var(--color-4);
    height: var(--h-nav);

    form {
      flex: 1;
      @include flex(row, flex-start, stretch, nowrap);
      padding-top: 0.4em;

      input {
        border-right-width: 0px;
        border-radius: 3px 0px 0px 3px;
        width: 400px;

        @media (max-width:991px) {
          padding: .2em 1.25em;
        }
      }

      button {
        background-color: var(--color-4);
        border: 1px solid rgba(var(--rgb-color-1) / 20%);
        border-radius: 0px 3px 3px 0px;
        color: var(--color-1);
        padding: 0em .75em;

        &:hover,
        &:active,
        &.active {
          color: $color-2
        }



        .lg-svg {
          font-size: 2em;
          margin-right: .5em;

          &:hover,
          &:active,
          &.active {
            color: $color-3;
          }

          color: $color-2;
        }

        svg {
          font-size: 1.5em;
        }
      }
    }

    ul {
      @include list();
      height: 100%;
      @include flex(row, space-between, center, nowrap);

      a,
      button {
        color: $black;
        transition: color var(--transition);
        text-align: center;

        &:hover,
        &:active,
        &.active {
          font-weight: 500;
          color: var(--color-1);
        }
      }

      .dropdown-toggle {
        color: $black;
        border: none;
        padding: 0px;

        &:hover,
        &:active,
        &.active {
          --bs-btn-active-color: $color-1;
          color: $color-1 !important;
          border: none;
        }
      }

      .dropdown-menu {
        top: 0.5em !important;
        border-top-right-radius: 0px;

        a {
          text-align: right;
          display: block;
        }
      }
    }
  }

  .profile-link {
    @include flex();

    img {
      @include rounedImg(30px);
      margin-right: 0.5em;
    }
  }
}


main {
  min-height: calc(100vh - var(--h-top) - var(--h-nav));

  @media (max-width: 991px) {
    min-height: calc(100vh - var(--h-top) - var(--f-height));
  }
}


/* Home page Section - Slider */
.main-slider {
  margin-bottom: 3rem;

  @media (max-width: 991px) {
    margin-bottom: 1rem;
  }

  .mainslides {
    width: 100%;
    height: fit-content;


    .slide {
      width: 100%;
      height: fit-content;
      position: relative;
      background-color: var(--color-1);
      transition: $transition;

      &-img {
        position: relative;
        top: 0px;
        left: 0px;
        width: 100%;
        height: auto;
        object-fit: contain;
        object-position: center center;
        z-index: 1;
      }

      a {
        display: flex;
        justify-content: center;
      }

      &-caption {
        position: absolute;
        top: 0px;
        width: 100%;
        z-index: 10;
        height: 100%;
        padding-right: 30%;
        color: #fff;
        @include flex(column, center, flex-start, nowrap);

        @media (max-width: 1199px) {
          padding-right: 10%;
        }

        @media (max-width: 991px) {
          padding-right: 0%;
        }

        h2 {
          text-transform: uppercase;
          font-size: 2.5em;
          font-weight: 700;
          text-align: left;

          @media (max-width: 991px) {
            font-size: 2em;
          }

          @media (max-width: 767px) {
            font-size: 1.75em;
          }

          @media (max-width: 575px) {
            font-size: 1.3em;
          }

          &::after,
          &::before {
            content: unset;
          }
        }

        p {
          font-family: var(--extra-font-fam);
          font-size: 1.1em;

          @media (max-width: 991px) {
            display: none;
          }
        }
      }
    }
  }
}

/* Home page Section - Info In Nums */
.infoInNums {
  &-item {
    @include flex(row, space-between, stretch, nowrap);
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 1em;
    box-shadow: 10px 10px 30px rgba(60, 100, 177, .2);
    height: 100%;

    &>div:first-child {
      @include flex(column, space-between, stretch, nowrap);
    }

    &>div:last-child {
      @include flex(column, flex-end, flex-end, nowrap);
    }
  }
}

/* Home page Section - About */
.sec-about {
  background-color: var(--color-4);
  padding: 2em 0em;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

/* Home page Section - Partners */
.sec-partners {
  background-color: var(--color-4);
  padding: 3em 0px 4em;

  @media (max-width: 575px) {
    padding: 2em 0px 3em;
  }

  img {
    width: 100%;
    height: 100px;
    object-fit: scale-down;
    object-position: 50% 50%;
    background-color: $white;
    border: 1px solid $light-gray;
    border-radius: 5px;

    @media (max-width: 1399px) {
      height: 95px;
    }

    @media (max-width: 1199px) {
      height: 90px;
    }

    @media (max-width: 575px) {
      height: 22vw;
    }
  }
}

/* Home page Section - Blog */
.article-mini {
  margin-bottom: 1em;

  h5 {
    @include clamp(2, 1.3em, false);
    font-family: $extra-font;
  }
}

.article-midi {
  background: $white;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  width: 300px;

  @media (max-width: 1199px) {
    width: 200px;
  }

  @media (max-width: 991px) {
    width: 140px;

  }

  @media (max-width: 575px) {
    width: 55vw;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: contain;


  }

  figcaption {
    padding: 1.5em 1em;

    @media (max-width: 991px) {
      padding: 1em .5em;
    }

    h6 {
      @include clamp(2, 1.3em, true);
      margin-bottom: .5em;
    }
  }

  &-text {
    @include clamp(2, 1.3em, true);

    &>* {
      font-family: $extra-font;
      line-height: 1.3em;
    }
  }

  &-more {
    margin-top: 1em;
    display: block;
    width: fit-content;
    margin-left: auto;
    color: $color-1;
    font-size: 0.9em;
    font-family: $extra-font;
    text-decoration: underline;
    transition: color $transition;

    &:hover {
      color: $color-2;
      text-decoration: underline;
    }
  }
}

.article-full {
  hr {
    background-color: $gray;
    margin: 1.5em 0em 2em;
    border: 0px;
    height: 1px;
    opacity: 1;

    @media (max-width: 575px) {
      margin: 1em 0em 1.5em;
    }
  }

  p {
    color: var(--total-black);
    margin-bottom: 2em;

    @media (max-width: 575px) {
      margin-bottom: 1em;
    }
  }

  img {
    max-width: 100%;
    border-radius: $radius;
    margin-bottom: 2em;

    @media (max-width: 575px) {
      margin-bottom: 1em;
    }
  }

  .bb-2 {
    border-bottom: 2px solid currentColor;
  }
}


/* Catalog */
.card {
  border: none;
  border-radius: 0px;
  width: 100%;

  @media (max-width: 575px) {
    font-size: 11px;
  }

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;

    @media (max-width: 1399px) {
      height: 175px;
    }

    @media (max-width: 991px) {
      height: 160px;
    }

    @media (max-width: 575px) {
      height: 32vw;
    }
  }

  &-fav {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: rgba(#ff8e37, 1);
    padding: 0.5em;
    z-index: 10;

    .btn-fav {
      color: white;
    }
  }

  &-cap {
    background-color: var(--color-1);
    color: #fff;
    position: relative;
    height: 68px;

    @media (max-width: 767px) {
      height: 50px;
    }

    @media (max-width: 575px) {
      height: 45px;
    }





    &-title {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: rgba(var(--bs-primary-rgb), 1);
      z-index: 10;
      @include flex(row, flex-start, center, nowrap);
      padding: 0 .75em;
      transition: 150ms linear;
      transition-property: bottom, background-color;


      display: flex;
      flex-direction: column;

      p {
        width: 100%;
      }

      justify-content: center;

      h5 {
        width: 100%;
        @include clamp(3, 1.3em, false);

        @media (max-width: 575px) {
          @include clamp(2, 1.3em, false);
        }
      }

      span {
        font-weight: 500;
        font-size: 1.1em;
      }
    }

    &-hidden {
      position: relative;
      z-index: 1;
      height: 100%;
      @include flex(row, space-between, center, nowrap);
      padding: 0 1em;

      @media (max-width: 575px) {
        padding: 0 .5em;
      }

      p {
        @include clamp(2, 1.2em, false);

        @media (max-width: 575px) {
          font-size: .9em;
        }
      }
    }


    // @media (min-width: 991px) {
    //   &:hover {
    //     .card-cap-title {
    //       bottom: 100%;
    //       background-color: rgba(var(--bs-primary-rgb), 0.8);
    //     }
    //   }

    // }
  }
}

.card-carousel {
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

/* Catalog filter Form */
.filter {
  background-color: $extralight-gray;
  border: 1px solid $gray;
  margin-bottom: 2em;

  &-top {
    @include flex(row, space-between, center, nowrap);
    background-color: $color-4;
    padding: 1.75em;
    border-bottom: 1px solid $gray;
  }

  &-main {
    padding: 1em 1.75em;
    font-family: $extra-font;
  }
}

.catalog-nav {
  border: 1px solid $gray;
  border-width: 1px 0px 1px 0px;
  margin-bottom: 1.5em;
  @include flex(row, space-between, center, nowrap);
  padding: 1em 0em;

  @media (max-width: 575px) {
    @include flex(column, space-between, stretch, nowrap);
  }

  ul {
    @include flex(row, flex-start, center, nowrap);

    @media (max-width: 575px) {
      justify-content: center;
    }
  }

  select {
    width: 200px;

    @media (max-width: 575px) {
      width: 100%;
      margin-top: 10px;
    }
  }

  p {
    @media (max-width: 991px) {
      display: none;
    }
  }
}

/* Offer page */
.page-offer {
  padding: 2em 0em 4em;

  h1 {
    font-size: 3.25em;
    font-weight: 900;
    color: $color-3;
    text-align: left;
  }

  h3 {
    font-size: 1.55em;
    font-weight: 400;
    color: $total-black;
    text-align: left;
  }

  p {
    white-space: pre-wrap;

  }

  &-info {
    // height: 100%;
    background: linear-gradient(156.98deg, #F3F7FF -12.48%, #D2E2FF 128.58%);
    @include boxShadow();
    border-radius: $radius-xl;
    padding: 2em;

    @include flex(column, stretch, nowrap);

    @media (max-width: 1399px) {
      padding: 1.5em;
    }

    .representative {
      @include flex(row, flex-start, center, nowrap);

      img {
        @include roundedImg(50px, 50%, cover);
        margin-right: .5em;
      }
    }

    ul {
      @include list();

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:first-child) {
          margin-top: 2em;
        }

        span {
          font-family: $extra-font;
          font-size: 1.1em;
          font-weight: 700;
          color: $darker;

          @media (max-width: 1399px) {
            font-size: 1em;
          }
        }

        strong {
          color: $total-black;
          font-size: 1.2em;
          font-weight: 500;
          margin-left: 0.25em;
          text-align: right !important;
          line-height: 100%;

          @media (max-width: 1399px) {
            font-size: 1.1em;
          }
        }
      }
    }

    .btn-fav {
      color: $color-2;
      font-size: 2em;
    }
  }

  &-cover {
    width: 100%;
    height: 600px;
    object-fit: cover;
    border-radius: 1.25em;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

    @media (max-width: 1399px) {
      height: 550px;
    }

    @media (max-width: 1199px) {
      height: 450px;
    }

    @media (max-width: 991px) {
      margin-bottom: 1em;
    }

    @media (max-width: 767px) {
      height: 400px;
    }

    @media (max-width: 575px) {
      height: 66vw;
    }
  }

  .list-contents {
    position: sticky;
    top: calc(var(--h-top) + var(--h-nav));
    @include list();
    font-family: $extra-font;
    font-size: 1.1em;

    li {

      a,
      button {
        display: block;
        padding: 1em;
        background-color: transparent;
        border-left: 5px solid transparent;
        transition: $transition;
        transition-property: background-color, border-color;

        &.left-card {
          width: 100%;

          img {
            width: 100%;
            object-fit: contain;
          }
        }

        &:hover,
        &:focus {
          background-color: $color-4;
        }

        &:active,
        &.active {
          background-color: $color-4;
          border-left: 5px solid $color-1;
        }
      }
    }
  }

  hr {
    background-color: $gray;
    height: 1px;
    border: none;
    opacity: 1;
    margin: 1.5em 0em;
  }

  .chapter {
    margin-bottom: 3em;

    @media (max-width: 767px) {
      margin-bottom: 2em;
    }

    @media (max-width: 575px) {
      margin-bottom: 1.5em;
    }

    .img-fluid {
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: cover;
    }

    p {
      white-space: pre-wrap;
    }

    ul:not(.tags-list) {
      list-style: none;

      li {
        position: relative;
        padding-left: .5em;


        &::before {
          content: "●";
          color: $color-1;
          position: absolute;
          left: -1em;
        }
      }
    }

    ol:not(.tags-list),
    ul:not(.tags-list) {
      margin-top: 1.5em;
      margin-bottom: 1.5em;
      padding-left: 1em;

      li {
        &:not(:first-child) {
          margin-top: 1em;
        }
      }
    }

    .tags-list {
      font-family: $extra-font;
      font-size: .9em;
      @include list();
      @include flex(row, flex-start, stretch, wrap);

      li {
        margin-right: .5em;
        margin-bottom: .5em;
        background-color: $color-4;
        color: $dark-gray;
        padding: .5em .75em;
        border-radius: $radius;
      }
    }
  }
}




/* Chat */
.chat {
  position: relative;


  &-head {
    @include flex(row, space-between, center, nowrap);
    border-bottom: 1px solid $light-gray;
    padding: .7em 0em;
  }

  &-window {
    display: flex;
    flex-direction: column-reverse;
    height: 500px;
    overflow: auto;
    padding: 1em 1.5em;
    max-height: 50vh;

    @media (max-width: 991px) {
      padding: 1em 1.5em;
      height: 500px;
    }

    @media (max-width: 767px) {
      padding: 1em;
    }

    @media (max-width: 575px) {
      padding: 1em 0.5em;
    }

    &-message {
      @include flex(row, flex-start, flex-start);

      &:not(:first-child) {
        margin-top: 1.5em;
      }

      img {
        width: 3.34em;
        height: 3.34em;
        border-radius: 50%;
        position: relative;
        z-index: 10;
        margin-right: 1em;
      }

      .text {
        flex: 1;
        @include flex(column, flex-start, flex-start, nowrap);

        .bubble {
          position: relative;
          width: fit-content;
          min-width: 50%;
          background: rgba($color: var(--pale-blue), $alpha: 0.2);
          border-radius: $radius;
          padding: 1em;
          font-weight: 400;
          font-size: 0.9em;
          text-align: left;
        }
      }

      &-mine {
        @extend .chat-window-message;

        img {
          margin-left: 1em;
          margin-right: 0px;
        }

        .text {
          align-items: flex-end;

          .bubble {
            background: rgba($color: var(--pale-green), $alpha: 0.2);
            text-align: right;
          }
        }
      }
    }
  }

  &-form {
    @include flex(row, flex-start, center, nowrap);
    border-top: 1px solid $light-gray;
    padding: .7em 0em;

    input {
      border: none;
      background-color: transparent;
      height: 100%;
    }
  }
}

.sec-chat {
  position: relative;

  h2 {
    font-size: 2.85em;
    margin-left: 3.5em;
    margin-bottom: 0.1em;
  }

  &-count {
    position: absolute;
    z-index: 10;
    top: 0px;
    left: 2em;
    width: 6em;
    height: 6em;
    border-radius: 50%;
    background-color: var(--font-color);
    color: var(--body-bg);
    text-align: center;
    @include flex(column, center, center, nowrap);

    .num {
      font-size: 1.34em;
    }

    .text {
      font-size: .86em;
    }
  }

  .chat {
    box-shadow: 0px 4px 15px 0px rgba(100, 100, 100, 0.08);
    padding-top: 2em;
  }
}




/* Registration page */
.sec-registration {
  margin-top: 4em;

  @media (max-width: 991px) {
    margin-top: 0em;
  }

  form {
    &.midi {
      width: 530px;

      @media (max-width: 1399px) {
        width: 480px;
      }

      @media (max-width: 991px) {
        width: 100%;
      }
    }

    &.mini {
      width: 440px;

      @media (max-width: 1399px) {
        width: 330px;
      }

      @media (max-width: 991px) {
        width: 100%;
        margin-top: 4em;
      }
    }
  }
}

/* Entering page */
.sec-login {
  .wrap {
    background: var(--second-bg);
    box-shadow: var(--shadow);
    backdrop-filter: blur(5px);
    padding: 2em 2em 1.5em 2em;
    border-radius: $radius;
    height: 100%;

    @media (max-width: 575px) {
      padding: 1.5em 1.5em 1em 1.5em;
    }

    .labeled-input {
      --bg: var(--second-bg);
    }
  }

  .cover {
    border-radius: $radius;
    color: var(--white);
    height: 395px;
    padding: 0 5em;
    @include flex(row, flex-start, center, nowrap);
    position: relative;
    overflow: hidden;
    @include safariFix();

    @media (max-width: 1399px) {
      height: 412px;
      padding: 0 3em;
    }

    @media (max-width: 991px) {
      height: 150px;
      overflow: visible;
      margin-bottom: 2em;
    }

    @media (max-width: 575px) {
      height: 100px;
      padding: 0 1em;
      margin-bottom: 1em;
    }

    h2 {
      line-height: 100%;
    }

    img {
      position: absolute;
      right: 0%;
      bottom: 0%;
      height: 85%;
      z-index: 10;

      @media (max-width: 1399px) {
        height: 70%;
      }

      @media (max-width: 991px) {
        height: 110%;
        right: 15%;
      }

      @media (max-width: 767px) {
        right: 5%;
      }

      @media (max-width: 575px) {
        height: 100%;
      }
    }
  }
}

/* Password restoration page */
.sec-password {
  .wrap {
    background: var(--second-bg);
    box-shadow: var(--shadow);
    backdrop-filter: blur(5px);
    padding: 2em 2em 1.5em 2em;
    border-radius: $radius;

    @media (max-width: 575px) {
      padding: 1.5em 1.5em 1em 1.5em;
    }

    .labeled-input {
      --bg: var(--second-bg);
    }
  }
}

.sec-favorites {
  ul {
    @include list();
  }

  .fav-item {
    @include flex(row, flex-start, center, nowrap);
    background-color: var(--white);
    @include lightShadow();
    padding: 0.65em 1.3em;
    border-radius: $radius;

    @media (max-width: 1399px) {
      padding: 0.5em 1em;
    }

    img {
      @include rounedImg(50px);
      margin-right: 1em;
    }

    h5 {
      flex: 1;
      @include clamp(2, 1.2em, false);
    }

    button {
      @include flex();
      color: var(--gray);
      font-size: 1.35em;

      svg {
        stroke-width: 1px;
      }
    }
  }
}


.category-card {
  border: 1px solid $light-gray;
  border-radius: $radius;
  @include flex(column, flex-start, center, nowrap);
  text-align: center;
  padding: 1.5em 1em;

  @media (max-width: 1199px) {
    padding: 1em;
  }

  img {
    @include roundedImg(100px, 50%, cover);
    margin-bottom: 1em;
  }

  h5 {
    font-size: 1.15em;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0.15em;
  }

  h6 {
    font-size: 1.15em;
    font-weight: 400;
    color: $dark-gray;
    text-align: center;
  }
}


/***********
ACCOUNT
***********/
.account {
  position: relative;
  overflow: hidden;
  padding: 2em 0em;

  hr {
    background-color: $light-gray;
    width: 100%;
    height: 1px;
    border: none;
    margin: 1.5em 0em;
    opacity: 1;
  }

  .return-title {
    display: none;

    @media (max-width: 991px) {
      @include flex(row, flex-start, center, nowrap);
      margin-bottom: 1em;
    }
  }

  @media (max-width: 991px) {
    h1 {
      color: var(--blue);
      font-size: 1.5em;
    }
  }

  @media (max-width: 575px) {
    h1 {
      font-size: 1.25em;
    }

    .return-icon {
      font-size: 1.6em;
      color: var(--blue);
    }
  }

  &-container {
    @include flex(row, flex-start, flex-start, nowrap);

    @media (max-width: 991px) {
      display: block;
    }
  }

  &-nav {
    width: 220px;

    @media (max-width: 991px) {
      width: 100%;
    }

    &-balance {
      background-color: var(--blue);
      color: var(--white);
      padding: 1em;
      border-radius: $radius;
      text-align: center;
      margin-bottom: 1em;

      div {
        @include flex();

        span {
          font-family: $title-font;
          font-size: 2em;
          margin-right: 0.34em;
          line-height: 90%;
        }

        svg {
          font-size: 1.5em;
          padding: 0.1em;
          border: 1px solid var(--white);
          border-radius: 50%;
        }
      }
    }

    ul {
      @include list();
      border: 1px solid $light-gray;
      border-radius: $radius;
      overflow: hidden;

      li {
        &:not(:first-child) {
          border-top: 1px solid $light-gray;
        }

        a {
          color: $black;
          font-size: .9em;
          @include flex(row, flex-start, center, nowrap);
          padding: 0.67em 1em;
          transition: $transition;
          transition-property: color, border-color;
          border-left: 5px solid transparent;

          @media (max-width: 991px) {
            font-size: 1.2em;
          }

          div {
            flex: 1;
          }

          .message {
            background-color: var(--color-2);
            color: var(--white);
            padding: 0.4em;
            min-width: 1.8em;
            min-height: 1.9em;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: nowrap;
            font-size: .8em;
            border-radius: 50%;
            font-size: 400;
          }

          &:hover,
          &:focus {
            color: $color-1;
          }

          &.active {
            color: $color-1;
            border-left: 5px solid $color-1;
          }
        }
      }
    }
  }

  &-main {
    flex: 1;
    margin-left: 3em;
    border: 1px solid $light-gray;
    border-radius: $radius;
    padding: 1em;

    @media (max-width: 1199px) {
      margin-left: 1.5em;
    }

    @media (max-width: 991px) {
      margin-left: 0em;
    }
  }

  .sec-profile {
    position: relative;



    .photo {
      width: 100%;
      height: 300px;
      object-fit: contain;
      border: 1px solid $light-gray;
      border-radius: $radius;

      @media (max-width: 1399px) {
        height: 250px;
      }

      @media (max-width: 1199px) {
        height: 200px;
      }

      @media (max-width: 767px) {
        height: 500px;
      }

      @media (max-width: 575px) {
        height: 90vw;
      }
    }

    .partner {
      @include flex(column, center, center, nowrap);
      width: 100%;

      img {
        @include roundedImg(50px, 50%, cover);
      }

      h6 {
        margin-top: .4em;
        font-weight: 400;
        line-height: 1.2em;
        text-align: center;
        font-size: 0.75em;
        color: $color-1 ;
        overflow: hidden;
        text-overflow: ellipsis;
        // white-space: nowrap;
        width: 100%;
      }
    }

    .card {
      img {
        height: 150px;

        @media (max-width: 1199px) {
          height: 135px;
        }

        @media (max-width: 575px) {
          height: 32vw;
        }
      }

      &-cap {
        height: 50px;

        &-title {
          h5 {
            @include clamp(2, 1.3em, false);
          }
        }
      }
    }

    .category-card {
      height: 100%;
    }
  }

  .light-bg {
    background-color: $color-5;
  }

  .sec-settings {

    .warning-text {
      text-align: center;
      background-color: $color-1;
      color: $color-5;
      padding: 1em;
      margin-bottom: 1.5em;
      font-weight: 700;
    }


    .files {
      &-label {
        position: relative;
        @include flex();
        transition: $transition;

        &:hover {
          cursor: pointer;
        }

        input {
          position: absolute;
          z-index: -1;
          opacity: 0;
          width: 1px;
          height: 1px;
          top: 0px;
          left: 0px;
          padding: 0px;
        }

        .icon {
          @include flex();
          width: 2.5em;
          height: 2.5em;
          border-radius: 50%;
          border: 1px solid rgba($color: var(--pale-blue), $alpha: 0.35);

          svg {
            color: var(--blue);
            font-size: 1.5em;
            stroke-width: 1.25px;
          }
        }

        .ind {
          position: absolute;
          bottom: -0.25em;
          right: 0px;
          color: var(--blue);
          font-weight: 600;
          line-height: 1em;
          font-size: 0.9em;

          &:empty {
            display: none;
          }
        }
      }
    }
  }

  &-offer {
    @include flex(row, space-between, center, nowrap);

    @media (max-width: 767px) {
      flex-wrap: wrap;
      align-items: start;
    }

    &-img {
      width: 140px;
      height: 100px;
      object-fit: cover;
      border: 1px solid $gray;
      border-radius: 4px;
      margin-right: 1em;

      @media (max-width: 575px) {
        width: 90px;
        height: 60px;
      }
    }

    &-info {
      flex: 1;
    }

    &-btns {
      gap: 10px;
      display: flex;
      flex-direction: column;

      .line {
        width: 325px;
        @include flex(row, flex-start, stretch, nowrap);
        font-size: .75em;
        gap: 10px;

        @media (max-width: 1199px) {
          width: 155px;
          flex-direction: column;
        }

        @media (max-width: 767px) {
          width: 100%;
          margin-top: 1em;
          flex-direction: row;
        }

        button {
          flex-grow: 1;

          @media (max-width: 1199px) {
            width: 100%;
          }
        }
      }
    }
  }

  &-reklama {
    @include flex(row, space-between, center, nowrap);

    @media (max-width: 767px) {
      flex-wrap: wrap;
      align-items: start;
    }

    &-img {
      width: 50%;
      height: auto;
      object-fit: contain;
      border: 1px solid $gray;
      border-radius: 4px;
      margin-right: 1em;

      @media (max-width: 575px) {
        width: 100%;
        height: auto;
        margin-bottom: 1em;
      }
    }

    &-info {
      flex: 1;
      margin-right: 1em;
    }

    &-btns {
      width: fit-content;
      @include flex(row, flex-start, stretch, nowrap);
      font-size: .75em;
      gap: 10px;

      @media (max-width: 1199px) {
        width: fit-content;
        flex-direction: column;
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-top: 1em;
        flex-direction: row;
      }

      button {
        flex-grow: 1;

        @media (max-width: 1199px) {
          width: 100%;
        }
      }
    }
  }

  .nav-tabs {
    border: none;

    .nav-item {
      &:not(:first-child) {
        margin-left: 1.5em;
      }
    }

    .nav-link {
      color: $dark-gray;
      border: none !important;
      padding: 0px;

      &:hover {
        color: $color-1;
      }

      &.active {
        color: $black;
      }
    }
  }

  .sec-messages {
    &-list {
      width: 100%;
      @include list();
      background-color: $white;
      border: 1px solid $extralight-gray;
      border-radius: $radius;

      li {
        &:not(:first-child) {
          border-top: 1px solid $extralight-gray;
        }
      }

      .general-chat {
        @include flex(row, flex-start, center, nowrap);
        padding: 0.75em 1.3em;

        .count {
          background-color: $color-0;
          color: $white;
          @include rounedImg(4.8em);
          @include flex(column, center, center, nowrap);
          font-size: 10px;
          text-align: center;
          line-height: 100%;
        }

        h6 {
          margin-left: 0.5em;
          flex: 1;
          text-align: left;
        }
      }
    }

    &-chat {
      width: 65%;
      position: relative;

      @media (max-width: 1399px) {
        width: 60%;
      }

      @media (max-width: 1199px) {
        width: 100%;
      }

      .chat-window {
        height: 600px;

        @media (max-width: 1399px) {
          padding: 1.5em;
        }

        @media (max-width: 575px) {
          height: 400px;
        }
      }

      .chat-form {
        @media (max-width: 1399px) {
          padding: 0 1.5em;
        }
      }
    }
  }

  .partnerRequest {
    @include flex(row, space-between, center, nowrap);

    @media (max-width: 767px) {
      flex-wrap: wrap;
      align-items: start;
    }

    &-img {
      @include roundedImg(50px, 50%, cover);
      margin-right: 1em;
    }

    &-btns {
      width: 340px;
      @include flex(row, flex-start, stretch, nowrap);
      font-size: .75em;
      gap: 10px;

      @media (max-width: 1199px) {
        width: 180px;
        flex-direction: column;
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-top: 1em;
        flex-direction: row;
      }

      button,
      a {
        flex-grow: 1;

        @media (max-width: 1199px) {
          width: 100%;
        }
      }
    }
  }

  .search-form {
    input {
      background-color: $color-4;
      width: 300px;
      border: none;
    }
  }
}

[data-theme='dark'] .account {
  .account-bg {
    display: none;
  }
}

.user {
  position: relative;
  @include flex(row, flex-start, center, nowrap);
  @include lightShadow();

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }

  &-photo {
    position: relative;
    z-index: 2;
    width: 110px;
    height: 110px;
    border-radius: 100%;
    overflow: hidden;
    @include safariFix();
    border: 5px solid var(--white);
    box-sizing: content-box;

    @media (max-width: 1399px) {
      width: 100px;
      height: 100px;
    }

    @media (max-width: 767px) {
      width: 90px;
      height: 90px;
    }

    @media (max-width: 575px) {
      width: 70px;
      height: 70px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      z-index: 0;
    }

    &::after {
      content: "";
      @include absBG(1, 50%, 50%);
      background: linear-gradient(to bottom, transparent, var(--total-black) 50%);
      background-size: cover;
    }

    label {
      cursor: pointer;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      bottom: 0.25em;
      color: var(--white);
      font-size: 1.34em;
      transition: $transition;

      &:hover {
        color: var(--green) !important;
      }
    }
  }

  &-main {
    position: relative;
    z-index: 1;
    height: 144px;
    flex: 1;
    position: relative;
    margin-left: -60px;
    padding-left: 72px;
    @include flex(column, center, flex-start, nowrap);

    @media (min-width: 768px) {
      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 70%;
        background-color: #ebf3fc;
        border-radius: $radius 0 0 $radius;
      }

      &::after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0px;
        right: 0px;
        height: 100%;
        width: 50%;
        background-color: #ebf3fc;
        transform: skewX(-25deg);
        border-radius: $radius;
      }
    }

    @media (max-width: 767px) {
      background-color: var(--extrapale-gray);
      border-radius: $radius;
      padding-left: 65px;
      height: 120px;
    }

    @media (max-width: 575px) {
      height: 100px;
    }

    .title {
      font-family: $title-font;
      font-size: 2em;
      line-height: 100%;
      @include clamp(2, 1em);
    }
  }

  &-info {
    @include list();
    @include flex(column, flex-start, flex-end, nowrap);
    margin-left: -1em;
    width: 215px;

    @media (max-width: 1399px) {
      width: 200px;
    }

    @media (max-width: 767px) {
      font-size: 0.9em;
      width: 100%;
      margin-left: 0em;
      margin-top: 1em;
      flex-direction: row;
      justify-content: center;
      gap: 1em;
    }

    li {
      position: relative;
      padding: 0.6em;
      height: 41px;
      color: var(--black);
      font-weight: 600;
      width: 100%;
      @include flex(row, space-between, center, nowrap);

      @media (min-width: 768px) {
        &:first-child {
          width: 79%;
        }

        &:nth-child(2) {
          width: 89.5%;
        }

        &::before {
          content: "";
          position: absolute;
          z-index: -1;
          top: 0px;
          left: 0px;
          height: 100%;
          width: 50%;
          background-color: var(--white);
          transform: skewX(-25deg);
          border-radius: $radius;
        }

        &::after {
          content: "";
          position: absolute;
          z-index: -1;
          top: 0px;
          right: 0px;
          height: 100%;
          width: 70%;
          background-color: var(--white);
          border-radius: 0 $radius $radius 0;
        }
      }

      @media (max-width: 767px) {
        background-color: var(--white);
        border-radius: $radius;
        flex-direction: column;
        height: auto;
        width: 23%;
      }

      div {
        @include flex();

        @media (max-width: 767px) {
          flex-direction: column;
        }

        svg {
          font-size: 1.6em;
          margin-right: 0.34em;

          @media (max-width: 767px) {
            color: var(--blue);
          }

          &.svg {
            stroke-width: 1.3px;
          }

          &.path {
            path {
              stroke-width: 1.3px;
            }
          }
        }
      }

      &:not(:first-child) {
        margin-top: 11px;
      }
    }
  }

  .qr-code {
    display: block;
    width: 100px;
    align-self: flex-start;

    @media (max-width: 1199px) {
      width: 80px;
    }

    @media (max-width: 767px) {
      position: absolute;
      top: 1em;
      right: 1em;
      z-index: 10;
    }

    @media (max-width: 575px) {
      width: 50px;
    }
  }
}

.settings-line {
  padding: 1em;
  color: var(--total-black);
  display: grid;
  grid-template-columns: 150px 110px auto 140px 120px 60px;
  grid-template-rows: auto;
  grid-template-areas: "session system browser ip region btns";
  gap: 0.5em;

  // @media (max-width: 1199px) {
  //   flex-direction: column;
  //   align-items: stretch;
  // }
  @media (max-width: 1199px) {
    grid-template-columns: 1fr 1fr 80px;
    grid-template-areas: "session system btns"
      "browser browser browser"
      "ip ip region";
    border: 1px solid var(--pale-gray);
    border-radius: $radius;
  }

  @media (max-width: 767px) {
    font-size: 0.8em;
    gap: 0.25em;
    grid-template-columns: 1fr 1fr 60px;
  }

  @media (max-width: 575px) {
    font-size: 1em;
  }

  .session {
    grid-area: session;
  }

  .system {
    grid-area: system;
    text-align: left;

    @media (max-width: 1199px) {
      text-align: right;
    }
  }

  .browser {
    grid-area: browser;
    text-align: center;

    @media (max-width: 1199px) {
      text-align: left;
    }
  }

  .ip {
    grid-area: ip;
    text-align: center;

    @media (max-width: 1199px) {
      text-align: left;
    }
  }

  .region {
    grid-area: region;
    text-align: center;

    @media (max-width: 1199px) {
      text-align: right;
    }
  }

  .btns {
    grid-area: btns;
    @include flex(row, flex-end, center, nowrap);

    button {
      @include flex();
      color: var(--blue);
      font-size: 1.35em;
      transition: $transition;

      &:hover {
        color: var(--light-blue);
      }

      &:active {
        color: var(--dark-blue);
      }
    }
  }
}

.offer-line-2 {
  @include flex(row, space-between, center, nowrap);
  padding: 1em;
  color: var(--total-black);

  @media (max-width: 1199px) {
    flex-direction: column;
    align-items: stretch;
    border: 1px solid var(--pale-gray);
    border-radius: $radius;
  }

  .descr {
    flex: 1;
    @include clamp(2, 1.2em);

    @media (max-width: 1199px) {
      margin-bottom: 1em;
      @include clamp(4, 1.2em);
    }
  }

  .price {
    width: 100px;
    text-align: right;

    @media (max-width: 1199px) {
      flex: 1;
    }
  }

  .btns {
    width: 60px;
    @include flex(row, flex-end, center, nowrap);

    button {
      @include flex();
      color: var(--blue);
      font-size: 1.35em;
      transition: $transition;

      &:hover {
        color: var(--light-blue);
      }

      &:active {
        color: var(--dark-blue);
      }
    }
  }
}

.offer-line-3 {
  padding: 1em;
  color: var(--total-black);
  display: grid;
  grid-template-columns: 145px 110px 1fr 200px 130px 80px;
  grid-template-rows: 1fr;
  grid-template-areas: "date id descr seller status price";

  @media (max-width: 1399px) {
    grid-template-columns: 85px 90px 1fr 200px 130px 80px;
    font-size: 13px;
  }

  @media (max-width: 1199px) {
    border: 1px solid var(--pale-gray);
    border-radius: $radius;
    font-size: 14px;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
    gap: 0.5em;
    grid-template-areas: "descr descr"
      "id id"
      "status status"
      "seller date"
      "seller price";
  }

  @media (max-width: 767px) {
    font-size: 13px;
  }

  &>div {
    @include flex(row, flex-start, center, nowrap);
  }

  .date {
    grid-area: date;

    @media (max-width: 1399px) {
      @include flex(column, center, flex-start, nowrap);
    }

    @media (max-width: 1199px) {
      align-items: flex-end;
    }
  }

  .id {
    grid-area: id;
  }

  .descr {
    grid-area: descr;
  }

  .seller {
    grid-area: seller;
    @include flex(row, flex-start);

    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 0.75em;

      @media (max-width: 1199px) {
        width: 40px;
        height: 40px;
      }
    }
  }

  .status {
    grid-area: status;
  }

  .price {
    grid-area: price;

    @media (max-width: 1199px) {
      justify-content: flex-end;
    }
  }
}

.feedback-line {
  position: relative;
  @include flex(row, flex-start, flex-start, nowrap);

  img {
    --w: 50px;
    @include rounedImg(var(--w));
    border: 5px solid #fdfdfd;
    box-sizing: content-box;
    position: relative;
    z-index: 2;
    margin-top: 0.25em;
  }

  &-main {
    position: relative;
    z-index: 1;
    flex: 1;
    margin-left: -40px;
  }

  &-top {
    @include flex(row, space-between, center, nowrap);
    padding: 0px 1em 0px 60px;
    margin-bottom: 0.5em;

    @media (max-width: 575px) {
      @include flex(column, center, flex-start, nowrap);
      padding: 0px 1em 0px 45px;
    }
  }

  &-text {
    background-color: var(--white);
    @include lightShadow();
    border-radius: $radius;
    padding: 1em 1em 1.3em 60px;
    font-weight: 300;

    @media (max-width: 575px) {
      padding: 1em 1em 1.3em 45px;
    }
  }
}

.purchase-line {
  @include flex(row, space-between, center, nowrap);
  gap: 2em;
  color: var(--total-black);
  background-color: var(--white);
  @include lightShadow();
  border-radius: $radius;
  padding: 0.6em 1em;

  @media (max-width: 1199px) {
    @include flex(column, space-between, flex-start, nowrap);
    gap: 1em;
    padding: 1em 1.5em;
  }

  &-text {
    flex: 1;
  }

  &-user {
    @include flex(row, flex-start, center, nowrap);

    img {
      @include rounedImg(50px);
    }

    h6 {
      font-size: 1.067em;
      font-weight: 500;
      text-align: left;
    }

    button {
      display: flex;
      transition: $transition;

      &:hover {
        transform: scale(1.3);
      }
    }

    @media (max-width: 1199px) {
      width: 100%;

      div {
        flex: 1;
      }
    }
  }
}

.operation-line {
  padding: 1em;
  color: var(--total-black);
  display: grid;
  grid-template-columns: 21% 21% 21% 21% 16%;
  grid-template-rows: 1fr;
  grid-template-areas: "date id type stat sum";

  @media (max-width: 1199px) {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas: "date date date stat stat"
      "id type type sum sum";
    gap: 0.5em;
    font-size: 0.9em;
  }

  @media (max-width: 767px) {
    gap: 1em 1em;
    font-size: 1em;
  }

  .date {
    grid-area: date;
  }

  .id {
    grid-area: id;

    @media (max-width: 1199px) {
      font-size: 0.9em;
      color: var(--gray);
    }
  }

  .type {
    grid-area: type;
  }

  .stat {
    grid-area: stat;

    @media (max-width: 1199px) {
      text-align: right;
    }
  }

  .sum {
    grid-area: sum;

    @media (max-width: 1199px) {
      text-align: right;
    }
  }
}

.appeal-line {
  background-color: var(--white);
  border-radius: $radius;
  @include lightShadow();
  padding: 1em;
  color: var(--total-black);
  display: grid;
  grid-template-columns: 35% 13% 22% 20% 10%;
  grid-template-rows: 1fr;
  grid-template-areas: "subject id status time btns";

  @media (max-width: 1199px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas: "date id"
      "subject subject"
      "status btns";
    gap: 0.5em;
  }

  .subject {
    grid-area: subject;
  }

  .id {
    grid-area: id;

    @media (max-width: 1199px) {
      text-align: right;
    }
  }

  .status {
    grid-area: status;
  }

  time {
    grid-area: time;
  }

  .btns {
    grid-area: btns;
    @include flex(row, flex-end, center, nowrap);
  }
}

.dialog-preview {
  @include flex(row, flex-start, center, nowrap);
  padding: 0.75em 1.3em;
  border-radius: $radius;
  position: relative;

  &.active {
    background-color: var(--extrapale-gray);
  }

  .img {
    position: relative;
    margin-right: 1.5em;

    img {
      @include rounedImg(3em);
      position: relative;
      z-index: 1;
    }

    .indicator {
      position: absolute;
      bottom: 0em;
      right: 0em;
      width: 0.6em;
      height: 0.6em;
      border-radius: 50%;
      z-index: 10;
      background-color: $gray;

      &.active {
        background-color: $green;
      }
    }
  }

  .text {
    flex: 1;


    h6 {
      @include clamp(1, 1.2em, true);
    }

    time {
      font-weight: 300;
      color: $gray;
      font-size: 0.7em;
      margin-left: 1em;
    }

    p {
      @include clamp(1, 1.2em, true);
      font-weight: 300;
      color: $dark-gray;
    }

    .count {
      background-color: $color-4;
      color: $black;
      width: 1.4em;
      height: 1.4em;
      @include flex(row, center, center, nowrap);
      font-size: 0.8em;
      border-radius: 50%;
      font-size: 400;
    }

    .del {
      @include flex();
      font-size: 1.3em;
      color: $red;
    }
  }
}

/***********
ACCOUNT END
***********/
.privacy-policy {

  h4,
  p {
    margin-bottom: 1em;
  }

  h4:not(:first-child) {
    margin-top: 2em;
  }
}

.rules {
  hr {
    border: none;
    opacity: 1;
    height: 1px;
    background-color: var(--extrapale-gray);
    margin: 0.6em 0 1.2em;
  }

  .rules-wrapping {
    background: var(--glass);
    box-shadow: var(--shadow);
    backdrop-filter: blur(5px);
    border-radius: $radius;
    --padX: 2em;
    --padY: 1em;

    @media (max-width: 575px) {
      --padX: 1em;
    }

    &-top {
      background-color: var(--extrapale-gray);
      border-radius: $radius $radius 0px 0px;
      padding: var(--padY) var(--padX);

      ul {
        @include list();
        @include flex();
        padding: 0.25em 1em;
        font-weight: 700;
        font-size: 1.2em;

        &.line-2 {
          .descr {
            flex: 1;
          }

          .price {
            width: 100px;
            text-align: right;
          }

          .btns {
            width: 60px;
          }
        }

        &.line-3 {
          display: grid;
          grid-template-columns: 140px 110px 1fr 200px 130px 80px;
          grid-template-rows: 1fr;
          grid-template-areas: "date id descr seller status price";

          @media (max-width: 1399px) {
            grid-template-columns: 83px 90px 1fr 200px 130px 80px;
            font-size: 1em;
          }

          .date {
            grid-area: date;
          }

          .id {
            grid-area: id;
          }

          .descr {
            grid-area: descr;
          }

          .seller {
            grid-area: seller;
          }

          .status {
            grid-area: status;
          }

          .price {
            grid-area: price;
          }
        }

        &.line-operation {
          display: grid;
          grid-template-columns: 21% 21% 21% 21% 16%;
          grid-template-rows: 1fr;
          grid-template-areas: "date id type stat sum";

          .date {
            grid-area: date;
          }

          .id {
            grid-area: id;
          }

          .type {
            grid-area: type;
          }

          .stat {
            grid-area: stat;
          }

          .sum {
            grid-area: sum;
          }
        }

        &.line-appeal {
          display: grid;
          grid-template-columns: 35% 13% 22% 20% 10%;
          grid-template-rows: 1fr;
          grid-template-areas: "subject id status date btns";

          .subject {
            grid-area: subject;
          }

          .id {
            grid-area: id;
          }

          .status {
            grid-area: status;
          }

          .date {
            grid-area: date;
          }

          .btns {
            grid-area: btns;
          }
        }
      }
    }

    &-main {
      padding: var(--padY) var(--padX);

      ul {
        @include list();

        li {
          border-bottom: 1px solid rgba($color: var(--pale-blue), $alpha: 0.2);

          @media (max-width: 1199px) {
            border-bottom: none;
          }
        }
      }
    }
  }
}

.faq {
  .box {
    background-color: var(--total-black);
    color: var(--white);
    border-radius: $radius;
    position: relative;
    padding: 3em 40% 3em 2em;

    img {
      position: absolute;
      bottom: 0px;
      right: 5%;
      width: 35%;
      height: 110%;
      object-fit: scale-down;
      object-position: center bottom;
      z-index: 0;
    }
  }

  ul {
    @include list();

    li {
      border-bottom: 1px solid rgba($color: var(--pale-blue), $alpha: 0.35);
      padding: 0.5em 0px;
      margin-bottom: 1em;

      a {
        color: var(--blue);
        font-weight: 600;
        transition: $transition;

        &:hover,
        &:focus,
        &:active {
          color: var(--dark-blue);
        }
      }
    }
  }
}

/* Footer */
footer {
  height: var(--f-height);
  background-color: var(--color-0);
  color: $white;
  font-family: $extra-font;
  font-size: 0.9em;

  @media (max-width: 991px) {
    background-color: var(--color-1);
    padding: 0em;
    position: sticky;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 10000;
  }

  .footer-social {
    @include flex(row, flex-start, center, nowrap);
    @include list();

    li {
      &:not(:first-child) {
        margin-left: 1em;
      }



      a {
        background-color: rgba($color: $gray, $alpha: 0.2);
        font-size: 1.25em;
        color: $white;
        width: 1.75em;
        height: 1.75em;
        border-radius: 50%;
        @include flex();
        transition: background-color $transition;

        &:hover {
          background-color: var(--color-2);
        }

        &:active {
          background-color: var(--color-3);
        }
      }
    }
  }

  .dev-link {
    width: fit-content;
    @include flex(row, center, center, nowrap);
    font-size: 16px;

    svg {
      margin-left: 0.3em;
      font-size: 1.2em;
    }
  }

  .footer-menu {
    column-count: 2;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;

    li:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  .subscribe {
    input:not([type="checkbox"]) {
      border: none;
      border-radius: $radius 0px 0px $radius;
      background-color: rgba($color: $gray, $alpha: 0.2);
    }

    input {
      color: white;
    }


    button {
      border-radius: 0px $radius $radius 0px;
    }
  }

  .nav-mobile {
    height: 100%;
    width: 100%;

    ul {
      height: 100%;
      @include list();
      @include flex(row, space-between, center, nowrap);

      li {

        a,
        button {
          @include flex(column);
          font-size: 14px;
          color: $white;
          transition: $transition;

          @media (max-width: 575px) {
            font-size: 10px;
          }

          svg {
            font-size: 2em;
          }

          &:hover,
          &:active,
          &.active {
            color: var(--color-3);
          }
        }
      }
    }
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 1em;
  right: 1em;
  z-index: 100;
  @include flex(row, center, center, nowrap);
  font-size: 2em;
  border-radius: 50%;
  color: $white;
  background-color: rgba($color: $dark-gray, $alpha: 0.25);
  opacity: 0;
  transition: $transition;
  transition-property: opacity, background-color;

  &:hover {
    background-color: $color-2;
    cursor: pointer;
  }

  &.show {
    opacity: 1;
  }

  @media (max-width: 991px) {
    right: .35em;
    bottom: calc(var(--f-height) + 1em);
  }
}

.cursor {
  cursor: pointer;
}

/* Modal */
.modal {
  &-content {
    background-color: var(--white);
    border: none;
    border-radius: $radius;
  }

  &-header {
    position: relative;
    height: 35px;
    padding: 0px;
    background-color: var(--extrapale-gray);
    border: none;

    .warning {
      position: absolute;
      right: 2em;
      bottom: 0.25em;

      @media (max-width: 991px) {
        width: 20%;
      }
    }

    .btn-close {
      background: url(imgs/close.svg) no-repeat center;
      background-size: contain;
      background-color: var(--white);
      width: 1.34em;
      height: 1.34em;
      padding: 0px;
      margin: 0px;
      border-radius: 50%;
      overflow: hidden;
      @include safariFix();
      transition: $transition;
      position: absolute;
      top: 0.5em;
      right: 0.5em;
      z-index: 1;

      &:hover {
        background-color: var(--dark-blue);
      }
    }
  }

  &-body {
    padding: 1.34em 2.68em;

    .modal-dialog {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
    }

    img {
      max-width: 100%;
      height: 80vh;
      object-fit: contain;
    }

    @media (max-width: 575px) {
      padding: 1.34em;
    }
  }
}

.offcanvas {
  background-color: var(--body-bg);
  color: var(--font-color);

  &-body {
    position: relative;

    ul {
      @include list();
      background: var(--glass);
      border-radius: $radius;
      padding: 0 1em;
      font-size: 1.2em;
      box-shadow: var(--shadow);
      backdrop-filter: blur(5px);

      li {
        a {
          display: block;
          padding: 0.75em 0;
          color: inherit;
        }

        &:not(:first-child) {
          border-top: 1px solid var(--light-gray);
        }
      }
    }

    .sec-promo {
      h1 {
        font-size: 2.2em;
      }

      img {
        width: 40%;
        margin-top: -10%;
      }
    }

    .dev-link {
      @include flex();
    }

    .close {
      position: absolute;
      top: calc(var(--h-top) + 1em);
      right: 0px;
      font-size: 1.5em;
      @include flex();
      color: var(--gray);
      transition: $transition;
      padding: 0.5em;

      &:hover,
      &:active {
        color: var(--black);
      }
    }
  }

  &-top {
    background-color: transparent;
    height: fit-content !important;

    .offcanvas-body {
      padding-top: calc(var(--h-top) + var(--h-nav) + 2em);
      padding-bottom: 2em;
      background-color: var(--white);
      border-radius: 0px 0px $radius $radius;
    }
  }

  &-end {
    background-color: transparent;
    width: 100% !important;

    .offcanvas-body {
      padding-top: calc(var(--h-top) + var(--h-nav) + 2em);
      background-color: var(--white);
      border-radius: 0px 0px $radius $radius;
    }
  }
}

input[type="search" i]::-webkit-search-cancel-button {
  cursor: pointer;
}

.fav-modal {
  z-index: 1041;
}

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  top: 65px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
}

.notification {
  box-sizing: border-box;
  padding: 13px 20px;
  box-shadow: 0px 0px 2px #919eab1f, 0px 8px 24px -4px #919eab33 !important;
  border-radius: 12px;
  color: $black;
  background-color: $color-4;
  cursor: pointer;
  font-size: 0.9em;
  position: relative;
  margin-bottom: 15px;
}

.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.notification:hover,
.notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-exit {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-exit.notification-exit-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}

.notification-info {
  color: var(--light-blue);
}

.notification-success {
  color: var(--green);
}

.notification-warning {
  color: #f89406;
}

.notification-error {
  color: #bd362f;
}

.radio {
  padding: 1.2em .5em;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  margin-right: 2em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  max-width: 100%;

  strong {
    color: #22A2FF;
    font-size: 1.2em;

  }
}

.finance {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  align-items: center;

  .item {
    justify-content: center;
    display: flex;
    margin-bottom: 2em;


    @media (max-width: 991px) {
      flex-direction: column;


      fieldset {
        margin-bottom: 2em;
      }
    }

    button {
      width: 200px;
    }
  }
}

.activate {
  max-width: 400px;
}

.pointer {
  cursor: pointer;
}

.custom-telegram-button {
  @include flex(row, center, center, nowrap);
  padding: 0.6em 2em;
  text-align: center;
  width: fit-content;
  font-size: .9em;
}

.sec-blog {
  .new {
    display: flex;
    gap: 20px;


  }
}